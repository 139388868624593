import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import * as styles from './Footer.module.scss';
import { Layout } from '../Layout';

type Props = {
  className?: string;
};

export const Footer = ({ className }: Props) => {
  return (
    <footer className={classnames(styles.Footer, className)}>
      <Layout>
        <div className={styles.FooterContent}>
          <span>Copyrights @ Szkoła języków obcych Languart</span>
          <Link to='/regulamin'>
            Regulamin
          </Link>
        </div>
      </Layout>
    </footer>
  );
};
